
import { Component, Vue, Watch, PropSync } from "vue-property-decorator";
import {
  TechnicianAllPayload,
  TechnicianFilterQueryPayload,
  Technician,
} from "@/store/modules";
import { filterEmptyQuery } from "@/utility";
import Multiselect from "vue-multiselect";
import { SortQueryPayload } from "@/store/modules";

type OptionProps = { text: string; value: number };

@Component({ components: { Multiselect } })
export default class TechnicianMultiDropdown extends Vue {
  filter: TechnicianFilterQueryPayload = {};

  sort: SortQueryPayload = {
    sort: "firstname",
    order: "asc",
  };

  // @Model("change", { type: Array, default: () => [] })

  value: OptionProps[] = [];

  // @Model("change", { type: Number, required: false })
  mappedTags: Technician[] = [];

  options: Array<OptionProps> = [];

  optionIndex: Map<number, Technician> = new Map();

  @PropSync("selectedTech", { default: () => [], type: Array })
  selectedTechSync!: Technician[];

  debounceTimeout?: NodeJS.Timeout;

  searchString = "";
  // seaching = false;

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  @Watch("selectedTech")
  onSelectedTechSync() {
    // console.log(
    //   "selectedTech SYNC",
    //   this.selectedTechSync,
    //   this.selectedTechSync.length
    // );

    this.value = this.selectedTechSync.map((tech) => ({
      text: tech.name,
      value: tech.ID,
    }));
  }

  async onSearchChange(query: string) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(async () => {
      // this.seaching = true;
      this.searchString = query;
      await this.load();
      // this.seaching = false;
    }, 1200);
  }

  async fetchRender(payload: TechnicianAllPayload) {
    await Technician.dispatch("all", payload);
    this.updateOptions();
  }

  get loading() {
    // if (this.seaching) {
    //   return true;
    // }
    return !!Technician.store().state.entities.technician.fetching;
  }

  updateOptions(): Array<any> {
    const data = Technician.query().withAll().get();

    this.options = data.map((tech) => ({
      text: tech.name,
      value: tech.ID,
    }));

    this.optionIndex = new Map(data.map((tech) => [tech.ID, tech]));

    return this.options;
  }

  compilePayload(
    merge: Partial<TechnicianAllPayload> = {}
  ): TechnicianAllPayload {
    return {
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): TechnicianAllPayload["filter"] {
    const f = filterEmptyQuery(this.filter);
    if (this.searchString) {
      f["firstname"] = this.searchString;
    }
    return { ...f };
  }

  get availableOptions() {
    // console.log(this.value, "val");
    return this.options;
    // return this.options.filter((opt) => this.value.indexOf(opt.text) === -1);
  }

  onSelect(selectedOption: OptionProps, id: number) {
    const { value } = selectedOption;
    const opt = this.optionIndex.get(value);
    if (opt) {
      // handler.change(`${opt.name}`);
      this.selectedTechSync.push(opt);
      this.$emit("tech-select", this.selectedTechSync);
    }
  }

  onRemove({ value }: OptionProps, id: number) {
    const i = this.selectedTechSync.findIndex((t) => t.ID === value);
    this.selectedTechSync.splice(i, 1);
    this.$emit("tech-select", this.selectedTechSync);
  }
}
